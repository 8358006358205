import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Whisper"
              description="Whisper is a real-time chat application built with the MERN stack, designed to provide seamless and secure messaging."
              ghLink="https://github.com/WalaEddine01/Whisper"/>
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="OrgSchool"
              description="Open Source Software about student Management System (SMS), is a software solution that facilitates the seamless organization and administration of student enrollment processes."
              ghLink="https://github.com/WalaEddine01/OrgSchool-portfolio-project"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="AirBnb Clone"
              description=" Developed a full-stack Airbnb replica using Python, Flask, and MySQL, featuring user authentication,
              property listings, and booking management."
              ghLink="https://github.com/WalaEddine01/AirBnB_clone_v4"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Simple Shell"
              description="The 'Simple Shell' project is an essential undertaking for any aspiring software engineer. In this project, you'll be tasked with creating a simplified Unix shell which is a command-line interface that allows users to interact with the operating system."
              ghLink="https://github.com/WalaEddine01/simple_shell"

            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
